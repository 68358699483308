<script setup lang="ts">
import { DocumentStatus, type ILeaseVariation, type IDocument } from '@register'

const props = defineProps<{
  document: IDocument | undefined
  variation: ILeaseVariation
}>()

defineEmits<{
  publish: []
  add: []
  unlink: [variables: UnlinkDocumentVariables]
}>()

const isNotPublished = computed(
  () => props.document?.status === DocumentStatus.NOT_PUBLISHED,
)
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="flex w-full justify-between px-4 pt-4 md:flex-row">
      <AmendmentDocumentTitle class="!text-gray-200" :document="document" />
      <div class="flex items-center">
        <Button color="primary" size="sm" @click="$emit('add')">
          Add, change or remove a term
        </Button>
      </div>
    </div>

    <!-- Not published status  -->
    <span
      v-if="document && isNotPublished"
      class="bg-error flex rounded-lg px-6 py-0.5 text-xs font-normal text-white"
    >
      Not published
    </span>
    <span
      v-else-if="!document?.filename"
      class="flex px-6 text-xs font-normal text-gray-400"
    >
      * Amendments without document will be automatically published after
      activeted
    </span>
  </div>
  <hr class="bg-gray-850 my-2 h-px border-t-0" />
  <AmendmentEditActionTitle :variation="variation" />
</template>
